import React, { useContext , useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu,Modal,Button } from 'antd';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
} from 'settings/constant';

const MobileMenu = ({ className }) => {
  // auth context
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageLogo] = useState('/images/logoJulianE.png'); 
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const navigations = [
    {
      label: <NavLink to={`${LISTING_POSTS_PAGE}`}>Mapa</NavLink>,
      key: 'listing',
    },
    {
      label: <a href="https://contacto.choroalerta.com/" target="_blank" rel="noopener noreferrer">Contacto</a>,
  
      key: 'menu-3',
    },
    {
      label: <a onClick={showModal}>¿Cómo funciona?</a>,
      key: 'menu-4',
    },
    {
      label: <a href="https://www.julianespinosa.com/" target="_blank" rel="noopener noreferrer"><img src={imageLogo} alt="Julián Espinosa" /></a>,
      key: 'menu-5',
    },
  ];
  const [image, setImage] = useState('/images/modal.png'); 
  return (
    <>
      <Menu className={className} items={navigations} />
      <Modal onCancel={() => handleCancel(false)} visible={isModalVisible} onOk={handleOk}  footer={[
            <Button key="close" onClick={() => handleCancel(false)}>Cerrar</Button>,
          ]}>
         <img src={image} alt="Instrucciones" />
      </Modal>
    </>
  );
};

export default MobileMenu;
