import React from 'react';
import Footers from 'components/Footer/Footer';

const Footer = () => {
  return (
    <Footers
      copyright={`Copyright @ ${new Date().getFullYear()} JulianEspinosa.com`}
    />
  );
};
export default Footer;