import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import Logo from 'components/UI/Logo/Logo';
import Navbar from 'components/Navbar/Navbar';
import { LayoutContext } from 'context/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import HeaderCitys from './HeaderCitys';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  LogoArea,
} from './Header.style';

const LogoIcon = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="4.000000pt" height="24.000000pt" viewBox="0 0 4752.000000 2414.000000"
  preserveAspectRatio="xMidYMid meet">
 
 <g transform="translate(0.000000,2414.000000) scale(0.100000,-0.100000)"
 fill="#000000" stroke="none">
 
 </g>
 </svg>
);

export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType1 = location.pathname === '/' ? 'transparent' : 'default';
  /* La aplicacion por defecto trae un header diferente el transparente para el home, en este caso se usa el mismo para todo*/
  const headerType =  'default';

  /*
  const locations = [
    { name:'Bogotá', lat:4.6520927, lng:-74.1040827},
    {name:'Soacha', lat:4.579, lng:-74.22},
    {name:'Zipaquirá', lat:5.022, lng:-74.0},
    {name:'Yopal', lat:5.337, lng:-72.395},
    {name:'Cali', lat:3.4516, lng:-76.532},
    {name:'Bucaramanga', lat:7.1254, lng:-73.1198},
    {name:'Medellín', lat:6.2442, lng:-75.5812},
    {name:'Neiva', lat:2.9984, lng:-75.3067},
    {name:'Barranquilla', lat:10.9639, lng:-74.7962},
    // Agrega más lugares según sea necesario
  ];

  const handleLocationClick = (location) => {
    // Lógica para mostrar el lugar específico en el mapa
    console.log(location);
    // Aquí debes agregar la integración con la API de Google Maps
  };
  */

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        top1={headerType1 }
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/choroapp-logo.svg"
                  title="Choro"
                  subtitle="Alerta"
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            headerType={headerType}
            headerType1={headerType1}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/choroapp-logo.svg"
                  title="Choro"
                  subtitle="Alerta"
                />
              </>
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
                <MobileMenu className="main-menu" />
                <AuthMenu className="auth-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
