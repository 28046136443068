import React, { useState } from 'react';
import styled from 'styled-components';

const SecondHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  top: 6rem;  // Ajusta esta propiedad según sea necesario para la posición
  background: rgba(255, 255, 255, 0.4); // Transparencia
  z-index: 10000; // Asegúrate de que esté por debajo del header principal
  overflow-x: auto;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center; // Asegura que los elementos estén alineados verticalmente al centro

    li {
      margin: 0 10px; // Ajusta el espaciado entre elementos según sea necesario
      padding: 10px;
      cursor: pointer;
      color: red;
      border-radius: 4px;
      transition: background-color 0.3s;

      &.active {
        background-color: white; // Usa el tema para el estado activo, añadido punto y coma
      }

      &:hover {
        background-color: white; // Usa el tema para el estado hover
      }
    }
  }

  @media (max-width: 768px) {
    ul {
      flex-wrap: nowrap; // Asegura que los elementos no se envuelvan
    }
  }
`;

const SecondHeader = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const locations = ['Bogota', 'Soacha', 'Zipaquira', 'Yopal', 'Cali', 'Bucaramanga', 'Medellin', 'Neiva', 'Barranquilla'];

  return (
    <SecondHeaderWrapper>
      <ul>
        {locations.map((location, index) => (
          <li
            key={index}
            className={activeIndex === index ? 'active' : ''}
            onClick={() => handleClick(index)}
          >
            {location}
          </li>
        ))}
      </ul>
    </SecondHeaderWrapper>
  );
};

export default SecondHeader;
