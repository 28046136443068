import React from 'react';
import { Routes, Route, } from 'react-router-dom';
import Layout from 'containers/Layout/Layout';
import Loader from 'components/Loader/Loader';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  CONTACT,
  PRIVACY_PAGE,
} from './settings/constant';

// public routes
const HomePage = React.lazy(() => import('containers/Home/Home'));
const ListingPage = React.lazy(() => import('containers/Listing/Listing'));
const SinglePageView = React.lazy(() =>import('containers/SinglePage/SinglePageView'));
const Contact = React.lazy(() =>import('containers/Contact/Contact'));
const PrivacyPage = React.lazy(() => import('containers/Privacy/Privacy'));
const NotFound = React.lazy(() => import('containers/404/404'));
// protected route

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={HOME_PAGE} element={<Layout />}>
      <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
               <ListingPage />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingPage />
            </React.Suspense>
          }
        />
       <Route
          path={`${SINGLE_POST_PAGE}/:slug`}
          element={
            <React.Suspense fallback={<Loader />}>
              <SinglePageView />
            </React.Suspense>
          }
        />
        <Route
            path={CONTACT}
            element={
              <React.Suspense fallback={<Loader />}>
                <Contact />
              </React.Suspense>
            }
          />
        <Route
          path={PRIVACY_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPage />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
